import { BreezeActionTypes, BreezeState } from "./types";

const initialState: BreezeState = {
  errors: {},
  breezeFlow: {
    business: null,
    businessLocations: [],
    user: null,
    userLocations: [],
    paymentMethods: [],
    pickupLocation: null,
    deliveryLocation: null,
    pickupInstruction: '',
    deliveryInstruction: '',
    orderExpenses: 0,
    currency: '',
    distance: 0,
    originBranch: null,
    billingBranch: null,
    pricing: null,
    deliveryType: '',
    deliveryFee: 0,
    commission: 0,
    orderRevenue: 0,
    tip: 0,
    tax: 0,
    rounding: 0,
    breezeTransactionFee: 0,
  },
  loading: false,
  calculatingFees: false,
  submittingOrder: false
};

const breezeReducer = (state = initialState, action: BreezeActionTypes): BreezeState => {
  switch (action.type) {
    case 'SET_BREEZE_FLOW':
      return {
        ...state,
        breezeFlow: action.payload,
      };
    case 'TOGGLE_BREEZE_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'TOGGLE_CALCULATING_FEES':
      return {
        ...state,
        calculatingFees: action.payload,
      };
    case 'TOGGLE_SUBMITTING_ORDER':
      return {
        ...state,
        submittingOrder: action.payload,
      };
    default:
      return state;
  }
};

export default breezeReducer;
